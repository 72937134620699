import { nextTick, reactive, ref } from 'vue'
import { cloneDeep } from 'lodash'
import { customFetch } from '@/lib/utils/Fetch'
import { transformHashKeys } from '@/lib/utils/Casing'
import { getReferrer } from '@/lib/utils/Location'

export function usePdfPackageData () {
  const buildData = ref(null)
  const loading = ref(0)
  const cached = {}

  let fetchable = (() => {
    const fake = Promise.resolve()
    fake.abort = () => {}
    return fake
  })()
  let loader = Promise.resolve()

  const abort = async (promise = fetchable) => {
    try {
      await promise?.abort()
    } catch (err) {
      console.error('[PDF] Failed to Abort', err)
    }
  }

  const fetchData = async (url) => {
    const previousFetch = fetchable
    const request = customFetch(url, { json: true })
    const promise = request.then(response => response.json()).then(transformHashKeys)
    promise.abort = request.abort
    fetchable = promise
    /* wait for proper abortion of previous fetch to prevent race conditions on assignment */
    await abort(previousFetch)

    return await promise
  }

  const errorMessage = (error, base) => {
    if (error.fetchResult) {
      const getMessage = (value) => {
        if (!value) return ''
        if (Array.isArray(value)) return value.map(getMessage).join('\n')
        if (value?.message) return value.message
        if (value?.messages) return value.messages.join('\n')
        return value
      }

      const message = getMessage(error.fetchResult.error || error.fetchResult.errors)

      return message
        ? `${base}:${message.includes('\n') ? '\n' : ' '}${message}`
        : base
    } else {
      return base
    }
  }

  const loadData = ({ type, patientId }) => {
    ++loading.value
    let resolve
    abort(fetchable)
    const previousLoader = loader

    // eslint-disable-next-line promise/param-names
    const promise = new Promise((r) => { resolve = r }).finally(() => { --loading.value })

    loader = promise

    if (!patientId) {
      return previousLoader.then(() => {
        buildData.value = {
          error: 'No patient provided',
        }
        resolve()
      })
    }

    if (!type) {
      return previousLoader.then(() => {
        buildData.value = {
          error: 'No PDF collection type provided, valid types are PDF Package or Casefile',
        }
        resolve()
      })
    }

    if (cached[patientId]?.[type]) {
      return previousLoader.then(() => {
        buildData.value = reactive(cloneDeep(cached[patientId][type]))
        resolve()
      })
    }

    try {
      const current = () => {
        if (loader !== promise) {
          throw new Error('aborted PDF Builder Request')
        }
      }

      nextTick(async () => {
        try {
          const referrer = getReferrer()
          const url = new URL(`/api/emr/patients/${patientId}/casefile/new`, window.location)
          console.log(url)
          url.searchParams.append('type', type)
          url.searchParams.append('packages', type === 'package')
          url.searchParams.append('referrer', referrer)
          const data = await fetchData(url)

          current()

          cached[patientId] = cached[patientId] || {}
          cached[patientId][type] = cloneDeep(data)

          await previousLoader
          current()

          buildData.value = reactive(data)
        } catch (error) {
          logger.error('[PDF] Error in PDF Builder Request', error)
          if (/aborted/.test(error.message)) return
          await previousLoader
          buildData.value = {
            error: errorMessage(error, 'Invalid PDF Builder Request'),
          }
        } finally {
          previousLoader.then(resolve)
        }
      })
    } catch (error) {
      logger.error('[PDF] Error creating PDF Builder Request', error)
      if (/aborted/.test(error.message)) return resolve()
      buildData.value = {
        error: errorMessage(error, 'Failed to create PDF Builder Request'),
      }
      previousLoader.then(resolve)
    }
  }

  return {
    buildData,
    getReferrer,
    loadData,
    loading,
  }
}
