<script setup>
import { computed, ref } from 'vue'
import { set } from 'lodash'
import { underscore, titleize } from '@/lib/utils/Casing'
import { InputSelect, InputTextbox } from '@/components/form'
import { getReferrer } from '@/lib/utils/Location'
import { useVModel } from '@/composables/useVModel'
import KButton from '@/components/kipu-ui/buttons/KButton'
import FragmentWrap from '@/components/FragmentWrap'

const props = defineProps({
  modelValue: { type: Object, default: () => ({}) },
  patientId: { type: Number, default: 0 },
  type: { type: String, required: true },
  enableReview: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue', 'blur', 'error', 'submit'])
const data = useVModel(props, emit)

const checkboxSections = [
  'casefileSections',
  'casefileOptions',
]

const formEl = ref(null)

const packages = computed(() => data.value?.packages?.filter(({ enabled }) => enabled) || [])
const packageableFromProcess = computed(() => !!data.value?.packageableFromProcess)
const packageOptions = computed(() => packages.value.map(({ id, name }) => ({ id, name })))
const selectedPackage = computed({
  get: () => data.value?.selectedPackage,
  set: (value) => {
    if (data.value) {
      data.value.selectedPackage = value ?? packageOptions.value.find(({ id }) => id === value?.id)
    }
  },
})
const splitGrid = computed(() => Object.keys(data.value?.casefileSections || {}).length > 5)

const toggleUseDateRange = () => {
  if (data.value.dateRange) {
    data.value.dateRange = null
  } else {
    const isoDate = (date) => date.toISOString().split('T')[0]

    const today = new Date()
    const start = isoDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7))
    data.value.dateRange = {
      active: true,
      start,
      end: isoDate(today),
    }
  }
}

const getFormData = () => {
  if (!formEl.value) throw new Error('Form Element not found')
  if (!formEl.value.reportValidity()) throw new Error('Form is invalid')
  const formData = new FormData(formEl.value)
  const submittable = {}
  for (let [key, value] of formData.entries()) {
    if (value === '') value = null
    set(submittable, key, value)
  }
  return submittable
}

const triggerSubmit = (event) => {
  try {
    const category = 'default'
    const patientId = props.patientId
    const formData = getFormData()

    formData.referrer = getReferrer()

    emit('submit', { data: formData, patientId, category })
  } catch (error) {
    data.value.submissionResponse = {
      success: false,
      errors: [error.message],
    }
  }
}

const validateDateRange = () => {
  if (data.value.dateRange) {
    const { start, end } = data.value.dateRange
    if (start && end && start > end) {
      data.value.dateRange.endError = 'End date must be after start date'
    } else {
      delete data.value.dateRange.endError
    }
  }
}
</script>

<template>
  <form
    ref="formEl"
    action=""
    class="tw-flex tw-flex-col tw-h-full tw-gap-4"
    @submit.prevent="triggerSubmit($event)"
  >
    <div
      class="
        tw-flex-grow tw-h-px tw-overflow-y-auto tw-p-6
        tw-bg-slate-100
        tw-rounded-lg
      "
    >
      <input
        type="hidden"
        name="patient_id"
        :value="patientId"
      >
      <input
        type="hidden"
        name="type"
        :value="type"
      >

      <div class="tw-flex tw-flex-col tw-gap-4">
        <div
          v-if="data?.submissionResponse?.success === false"
          class="tw-w-full"
        >
          <div
            v-if="data?.submissionResponse?.errors"
            class="tw-bg-k-red-300 tw-text-red-900 tw-p-2 tw-mb-2 tw-rounded-md tw-text-center"
          >
            <ul>
              <li
                v-for="(error, idx) in data.submissionResponse.errors"
                :key="idx"
              >
                {{ error }}
              </li>
            </ul>
          </div>
          <div
            v-else
            class="tw-bg-k-red-300 tw-text-red-900 tw-p-2 tw-mb-2 tw-rounded-md tw-text-center"
          >
            An error occurred submitting the requested data.
            Please try again.
          </div>
        </div>
        <div
          v-if="packageOptions?.length"
          class="tw-w-full md:tw-w-1/2 md:tw-pr-4"
        >
          <InputSelect
            v-model="selectedPackage"
            :options="packageOptions"
            name="casefile_options[pdf_package]"
            label="Select PDF Package"
            :placeholder="packageableFromProcess? 'Forms in Current Tab' : 'Select PDF Package'"
            :required="!packageableFromProcess"
          />
          <input
            v-if="selectedPackage?.id"
            type="hidden"
            name="casefile_options[pdf_package_id]"
            :value="selectedPackage?.id"
          >
        </div>
        <hr
          v-if="packageOptions?.length"
          class="
            tw-block tw-w-full
            tw-border-t tw-border-gray-300
          "
        >
        <div class="tw-grid tw-gap-4 tw-grid-cols-1 lg:tw-grid-cols-2 tw-auto-rows-min">
          <template
            v-for="(section, idx) in checkboxSections"
            :key="section"
          >
            <FragmentWrap
              :is="splitGrid ? null : 'div'"
              class="tw-grid tw-grid-cols-1 tw-auto-rows-min tw-gap-4"
            >
              <hr
                v-if="splitGrid && idx"
                class="
                  lg:tw-col-span-2
                  tw-block tw-w-full
                  tw-border-t tw-border-gray-300
                "
              >
              <h3
                class="tw-text-lg tw-font-medium tw-text-gray-900"
                :class="{
                  'lg:tw-col-span-2': !!splitGrid,
                }"
              >
                {{ titleize(section.replace("casefile", "pdf")) }}
              </h3>
              <template
                v-for="(value, key) in data[section] || {}"
                :key="key"
              >
                <label
                  class="tw-block tw-text-sm tw-font-medium tw-text-gray-900"
                  :class="{
                    'tw-col-start-1': !!idx,
                  }"
                >
                  <input
                    type="hidden"
                    :name="`${underscore(section)}[${underscore(key)}]`"
                    value="0"
                  >
                  <input
                    type="checkbox"
                    :name="`${underscore(section)}[${underscore(key)}]`"
                    :checked="value"
                    value="1"
                    @change="data[section][key] = !data[section][key]"
                  >
                  <span class="tw-inline-block">
                    {{ section === 'casefileSections' ? 'Include' : '' }}
                    {{ titleize(key).replace(/^include\s+/i, '') }}
                  </span>
                </label>
              </template>

              <div
                v-if="section === 'casefileOptions'"
                class="
                  tw-w-full tw-flex tw-flex-wrap xl:tw-flex-nowrap
                  tw-gap-4 xl:tw-gap-8 tw-col-start-1
                "
              >
                <label class="tw-block tw-flex-1 tw-text-sm tw-font-medium tw-text-gray-900 tw-whitespace-nowrap">
                  <input
                    type="checkbox"
                    name="toggle_date_range"
                    :checked="!!data.dateRange"
                    @change="toggleUseDateRange()"
                  >
                  <span class="tw-inline-block">
                    Add Date Range Filter
                  </span>
                </label>

                <div
                  v-if="data.dateRange"
                  class="tw-flex tw-w-full tw-flex-grow tw-gap-4"
                >
                  <div class="tw-w-1/2">
                    <InputTextbox
                      v-model="data.dateRange.start"
                      label="Start Date"
                      type="date"
                      name="casefile_options[start_date]"
                      required
                      @change="validateDateRange()"
                    />
                  </div>
                  <div class="tw-w-1/2">
                    <InputTextbox
                      v-model="data.dateRange.end"
                      label="End Date"
                      type="date"
                      name="casefile_options[end_date]"
                      :validation-error="data.dateRange.endError"
                      required
                      @change="validateDateRange()"
                    />
                  </div>
                </div>
              </div>
            </FragmentWrap>
          </template>
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-justify-between">
      <slot name="footer" />
      <div class="tw-flex tw-justify-end tw-gap-4">
        <KButton
          v-if="enableReview"
          mode="secondary"
          type="button"
          data-customize="true"
        >
          Customize
        </KButton>
        <KButton
          mode="primary"
          type="submit"
        >
          Generate
        </KButton>
      </div>
    </div>
  </form>
</template>
