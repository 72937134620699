<script setup>
import { watch } from 'vue'
import { useAppSlideoutStore } from '@emr/stores/AppSlideoutStore'
import { storeToRefs } from 'pinia'

defineOptions({ inheritAttrs: false })
const emit = defineEmits(['open', 'close'])
const store = useAppSlideoutStore()
const { isOpen, parent } = storeToRefs(store)

watch(isOpen, (value) => {
  if (value) {
    document.body.classList.add('kipu-vue-drawer-wrapper', 'kipu-vue-drawer-open')
    emit('open')
  } else {
    document.body.classList.remove('kipu-vue-drawer-open')
    emit('close')
  }
}, { immediate: !!isOpen.value })
</script>

<template>
  <Teleport
    :disabled="!isOpen"
    to="#vue-3-drawer"
  >
    <div
      ref="parent"
      :class="
        isOpen
          ? 'tw-relative tw-h-full tw-w-full tw-overflow-hidden'
          : 'tw-hidden'
      "
    />
  </Teleport>
</template>

<style>
#vue-3-drawer {
  @apply tw-hidden;
}
.kipu-vue-drawer-wrapper {
  &.kipu-vue-drawer-open {
    @apply tw-flex tw-flex-row tw-justify-between
      tw-h-screen tw-w-screen tw-overflow-hidden #{!important};

    main {
      @apply tw-max-w-full tw-h-full tw-overflow-auto tw-flex-1 #{!important};
    }

    #vue-3-drawer {
      @apply tw-block tw-h-screen tw-w-[50vw] tw-max-w-3xl tw-bg-white tw-overflow-hidden #{!important};
    }
  }
}
</style>
