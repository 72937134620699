<script setup>
import {
  defineAsyncComponent,
  inject,
  onMounted,
  ref,
  watch,
} from 'vue'
import ErrorBoundary from '@/components/ErrorBoundary'
import PermissionRequest from '@/components/notifications/PermissionRequest'
import TimeoutModal from '@/components/notifications/TimeoutModal'
import BackToTopButton from '@/components/kipu-ui/buttons/BackToTopButton'
import PdfPackage from '@/apps/printable/components/PdfPackage'
import SlideoutSingleton from './SlideoutSingleton'
import { useDosespotCounterStore } from '@/apps/dosespot/stores/DosespotCounterStore'
import { useLocalStorageListeners } from '@/composables/useLocalStorageListeners'

const dosespotCounterStore = useDosespotCounterStore()
const TranscribeTeleporter = defineAsyncComponent(() => import('@/apps/media/components/TranscribeTeleporter'))

const isSessionPage = () => /\/sign[_-](in|out)$/.test(new URL(location).pathname)
const hasMediaComponent = () => !!document.querySelector('[data-teleport="media-component"]')

const enableTimeout = ref(!window.disableSessionTimeout && !isSessionPage())
const enableMedia = ref(hasMediaComponent())
const enableNewPdfPackage = inject('enableNewPdfPackage')
const { currentUser } = useLocalStorageListeners()
if (isSessionPage()) currentUser.value = 0

watch(currentUser, (newValue) => {
  if (isSessionPage() && newValue) {
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }
}, { immediate: true })

onMounted(() => {
  dosespotCounterStore.onPageMount()
  if (!enableMedia.value) enableMedia.value = hasMediaComponent()
})
</script>

<template>
  <ErrorBoundary v-if="enableTimeout">
    <PermissionRequest />
  </ErrorBoundary>
  <ErrorBoundary v-if="enableTimeout">
    <TimeoutModal />
  </ErrorBoundary>
  <ErrorBoundary v-if="enableNewPdfPackage">
    <PdfPackage />
  </ErrorBoundary>
  <ErrorBoundary>
    <BackToTopButton />
  </ErrorBoundary>
  <ErrorBoundary v-if="enableMedia">
    <Suspense>
      <TranscribeTeleporter />
    </Suspense>
  </ErrorBoundary>
  <ErrorBoundary>
    <SlideoutSingleton />
  </ErrorBoundary>
</template>
